<template>
    <article class="services__content">
        <h2>16-plaatser</h2>
        <p>
            Indien u zich wenst te verplaatsen met een grotere groep, dan is dit ook perfect mogelijk. Wij kunnen ook een VIP-bus aanbieden.
        </p>
        <p><b>Opgelet:</b> deze 16-plaatsers zijn niet ingeschakeld in het reguliere taxivervoer en dus <b>altijd op voorhand te bestellen</b>.</p>
        <h3>Prijzen</h3>
        <p>Op aanvraag, contacteer ons via <a href="tel:092223323">09/222.33.23</a>.</p>
    </article>
</template>

<script>
    export default {
        name: "Bus"
    }
</script>