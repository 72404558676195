<template>
  <article class="services__content">
    <h2>Taxi</h2>
    <p>
      De taxicentrale is permanent bemand, dag en nacht. De dispatcher meldt u
      ook nog hoeveel de wachttijd bedraagt. Tijdens de spitsuren speelt de
      ervaring van de dispatcher een grote rol. Hij zal dan een geschatte tijd
      mededelen, bijvoorbeeld van 15 tot 20 minuten. Hou er wel rekening mee dat
      de taxi er altijd vroeger kan staan.
    </p>
    <p>
      Taxirijden is goedkoper dan je denkt want de gemiddelde prijs voor een
      stadsrit is ongeveer €9. Voor verdere bestemmingen hanteren wij vaste
      tarieven zodat u niet voor een verrassing komt te staan bij het einde van
      de rit.
    </p>
    <p>
      Vraag aan de dispatcher steeds de forfaitprijs en u zal aan een voordelig
      tarief op uw bestemming geraken.
    </p>
    <p>
      Onze chauffeurs behoren tot de best opgeleide van het land, zij worden
      continu gewezen op het belang van service en klantvriendelijkheid.
    </p>
    <ul>
      <li>Gemiddelde wachttijd: <b>15 minuten</b></li>
      <li>Vaste tarieven</li>
      <li>De best opgeleide chauffeurs</li>
    </ul>
    <h3>Prijzen</h3>
    <p>
      <b
        >Prijzen voor vertrek vanaf een standplaats worden bepaald door de stad
        Gent</b
      >, meer info over de tarieven vindt u op de website van de
      <a
        target="_blank"
        href="https://stad.gent/nl/mobiliteit-openbare-werken/mobiliteit/met-de-taxi/taxi-nemen-gent"
        >dienst Mobiliteit van de Stad Gent</a
      >.
    </p>
    <p>Voor alle andere trajecten wordt de prijs als volgt bepaald:</p>
    <table>
      <tr>
        <th></th>
        <th>F</th>
        <th>G<br />Bus 8 pers.</th>
        <th>Buitenstadstarief <br />F-tarief + K2-toets</th>
        <th>H<br />shuttle</th>
      </tr>
      <tr>
        <td>Instap dag (incl 3km of 2min)</td>
        <td>€12.00</td>
        <td>€14.00</td>
        <td>€12.00</td>
        <td>€60/u</td>
      </tr>
      <tr>
        <td>Nachttarief<br />22u - 6u</td>
        <td>€3.00</td>
        <td>€3.00</td>
        <td>€3.00</td>
        <td></td>
      </tr>
      <tr>
        <td>Prijs/km</td>
        <td>€2.60</td>
        <td>€2.60</td>
        <td>€3.75</td>
        <td></td>
      </tr>
      <tr>
        <td>Prijs/km spits<br />6u - 9u & 15u - 19u</td>
        <td>€3.00</td>
        <td>€3.00</td>
        <td>€4.20</td>
        <td></td>
      </tr>
      <tr>
        <td>Weekendtarief<br />vr 19u - ma 6u</td>
        <td>€3.00</td>
        <td>€3.00</td>
        <td>€4.20</td>
        <td></td>
      </tr>
      <tr>
        <td>Uurtarief<br />vanaf 2 min</td>
        <td>€57/u</td>
        <td>€60/u</td>
        <td>€60/u</td>
        <td></td>
      </tr>
    </table>

    <p>
      Onze prijzen worden automatische berekend in ons dispatch programma, FMS,
      volgens bovenstaande tabel.
    </p>
    <p>
      Een vaste prijs zonder zorgen. U weet perfect wat de taxirit zal kosten,
      zo komt u nooit voor verrassingen te staan.
    </p>
  </article>
</template>

<script>
export default {
  name: "Taxi",
};
</script>
