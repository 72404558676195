<template>
    <div class="services__content">
        <h2>Vervoer op maat</h2>
        <p>Speciale reservaties voor een feest, chauffeur die standby blijft, een chauffeur voor uw wagen ... dit zijn slechts enkele van de diensten op maat die V-tax aanbiedt.</p>
        <p>Mag het net dat ietsje meer zijn? Via onze zusterfirma <b>Huur een Stuur</b> kunnen wij premium wagens ter beschikking stellen, denk hierbij aan bijvoorbeeld de Mercedes E-Klasse.</p>
        <h3>Prijzen</h3>
        <p>Neem contact op via <a href="tel:092222222">09/222.22.22</a> of <a href="mailto:info@v-tax.be">info@v-tax.be</a> voor uw prijs op maat.</p>
    </div>
</template>

<script>
    export default {
        name: "Vip"
    }
</script>