<template>
    <article class="services__content">
        <h2>Limo-service</h2>
        <p>Wij verzorgen voor honderden bedrijven een complete service voor hun bezoekers en personeelsleden, zowel voor het transport van of naar de luchthaven als naar het hotel.</p>
        <p>Voor alle bestemmingen buiten de Gentse stadszone zijn vaste prijzen af te spreken.</p>
        <p>Om de service naar deze doelgroep toe nog te verhogen staat er een aparte business-dispatch en een afdeling VIP-chauffeurs met anonieme wagens voor u klaar.</p>
        <p>Onze airportservice behandelt onze klanten individueel, er worden dus nooit combinaties gemaakt met andere klanten, enkel op deze manier is een honderd procent correcte service mogelijk.</p>
        <p>Onze Limo-service is het professionele vervoer op maat voor uw bedrijf:</p>
        <ul>
            <li>Complete service voor bedrijven en hun personeelsleden en bezoekers</li>
            <li>Vaste prijzen af te spreken</li>
            <li>Aparte dispatch en een afdeling VIP-chauffeurs met anonieme wagens tot uw dienst</li>
            <li>Individuele airportservice (zonder combinaties met andere klanten)</li>
        </ul>
        <h3>Prijzen</h3>
        <p>Op aanvraag, contacteer ons via <a href="tel:092223323">09/222.33.23</a> en wij voorzien een prijs op maat.</p>
    </article>
</template>

<script>
    export default {
        name: "Limo"
    }
</script>