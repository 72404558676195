<template>
    <article class="services__content">
        <h2>Afstandsritten</h2>
        <p>Naast onze taxi’s die elke dag Gent doorkruisen, rijden onze taxi’s ook naar alle mogelijke bestemmingen.</p>
        <p>V-tax staat altijd tot uw dienst zelfs als u onverwacht een taxi nodig heeft.</p>
        <h3>Prijzen</h3>
        <p>Neem contact op via <a href="tel:092222222">09/222.22.22</a> voor onze meest voordelige tarieven voor al uw bestemmingen.</p>
        <p>Vraag steeds een forfaitaire prijs aan onze dispatcher of aan de chauffeur. Zo bent u steeds zeker van de juiste prijs en voorkomt u (onaangename) verrassingen.</p>
        <p>Aan de chauffeurs wordt steeds opdracht gegeven om het forfaitbedrag op voorhand te ontvangen.</p>
    </article>
</template>

<script>
    export default {
        name: "Distance"
    }
</script>