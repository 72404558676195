<template>
    <article class="services__content">
        <h2>Minibus</h2>
        <p>
            De minibus is de perfecte oplossing voor het vervoer van maximaal 7 personen.
        </p>
        <p>
            De taxicentrale is permanent bemand, dag en nacht.
            De dispatcher meldt u ook nog hoeveel de wachttijd bedraagt.
            Tijdens de spitsuren speelt de ervaring van de dispatcher een grote rol.
            Hij zal dan een geschatte tijd mededelen, bijvoorbeeld van 15 tot 20 minuten.
            Hou er wel rekening mee dat de taxi er altijd vroeger kan staan.
        </p>
        <p>Taxirijden is goedkoper dan je denkt want de gemiddelde prijs voor een stadsrit is ongeveer €8,50. Voor verdere bestemmingen hanteren wij vaste tarieven zodat u niet voor een verrassing
            komt te staan bij het einde van de rit.</p>
        <p>Vraag aan de dispatcher steeds de forfaitprijs en u zal aan een voordelig tarief op uw bestemming
            geraken.</p>
        <p>Onze chauffeurs behoren tot de best opgeleide van het land, zij worden continu gewezen op het
            belang van service en klantvriendelijkheid.</p>
        <ul>
            <li>Gemiddelde wachttijd: <b>15 minuten</b></li>
            <li>Vaste tarieven</li>
            <li>De best opgeleide chauffeurs</li>
        </ul>
        <h3>Prijzen</h3>
        <p>Prijzen zijn dezelfde als voor een gewone taxi, deze minibusje hebben dezelfde taximeter als een gewone taxi. Voor grotere afstanden zijn er aangepaste tarieven. Indien u meer info wenst kan u ons steeds contacteren op het nummer
            <a href="tel:092223323">09/222.33.23</a>.</p>
    </article>
</template>

<script>
    export default {
        name: "Minibus"
    }
</script>
